@import '~antd/dist/antd.css';

.App {
  height:100%;
  width:100%;
  background-image: url(./images/courier-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
